/**
 * 이폼/리포트 솔루션 서버 주소, 데이터 조회 Parameter, e-Form 여부, PDF 파일명 설정 정보 
 *
 * 작성자 : CHOSUNGHO
 * 작성일 : 24. 11. 5. 
 * 버전 : 1.0
 */

/**
 * e-Form 및 Report 요청 공통 기능 
 * 
 * @param {*} rdParams 
 * @param {*} isFormMode 
 * @param {*} pdfFileName 
 * @returns 
 */
export const createRdAgent = (rdParams = '', isFormMode = false, pdfFileName = '') => {
  let rdServer = '';
  
  // 로컬호스트 or 클라우드 스테이징 서버  
  if (process.env.VUE_APP_ENV === 'local' || process.env.VUE_APP_ENV === 'stg') {
    rdServer = `/rf [http://localhost:9040/DataServer/rdagent.jsp] /rsn [Dev]`; // 이폼/리포트 개발 (스테이징) DB 데이터 조회 
    // rdServer = `/rf [http://localhost:9040/DataServer/rdagent.jsp] /rsn [Prod]`; // 이폼/리포트 운영 DB 데이터 조회 

  // 그 외 운영 서버 연결 
  } else {
    rdServer = `/rf [http://localhost:9040/DataServer/rdagent.jsp] /rsn [Prod]`; // 이폼/리포트 운영 DB 데이터 조회 
  } 

  const rdParameter = '/rv ' + rdParams;  // 데이터 조회 Parameter 
  const formModeParam = isFormMode ? '/rformmode [2]' : ''; // 이폼 여부 ( true : 이폼, false : 리포트 )
  const pdfFileNameParam = pdfFileName ? `/pdfFileName [${pdfFileName}]` : ''; // pdf 파일명 설정

  // console.log('createRdAgent : ', `${rdServer} ${rdParameter} ${formModeParam} ${pdfFileNameParam}`.trim());

  return `${rdServer} ${rdParameter} ${formModeParam} ${pdfFileNameParam}`.trim(); 
};
